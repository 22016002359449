'use strict';

(function () {
  class AddEditFindingModalCtrl {
    constructor($scope, $uibModalInstance, $state, Upload, Image, Finding, Blade, options,
       usSpinnerService, toastr, Util, Auth,  $timeout, ActivityLog) {
      this.Util = Util;
      this.Blade = Blade;
      this.Finding = Finding;
      this.Upload = Upload;
      this.Image = Image;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.$timeout = $timeout;
      this.isDisabled = false;
      this.errors = {};
      this.findingDatePopup = {
        opened: false
      };
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });

      // for internal findings
      this.cavityInspectionTypes = [
        'LE Cavity', 'Between Main Shear Webs', 'Btwn Main Shear Webs and 3rd Web', 'TE Cavity'
      ];

      this.findingTypes = [
        'NO FINDING',
        'Internal Tank damage.leak',
        'Debris/ Loose items',
        'Cracks',
        'Wrinkles',
        'Laminate. Dry',
        'Delamination',
        'Previous Repair',
        'Impact Damage',
        'LE Erosion',
        'Scratch/ Gouge',
        'Blisters. Air inclusion',
        'Blisters. Open',
        'Cracks. Star shaped',
        'Pin Holes',
        'Drain Hole Blocked',
        'Coating. Thin. Laminate Exposed',
        'Coating runs or curtains',
        'Coating Uneven',
        'Chips',
        'Poor Fill',
        'Coating damage',
        'Thick TE',
        'Grease or Oil',
        'Dirt. Algae',
        'LE Tape Damage',
        'Spoiler Damage',
        'Stall Fence Damage',
        'Rain collar Damage',
        'Hatch damage/ defect',
        'Turbulator Tape Damage',
        'Turbulator Tape Missing',
        'Vortex Generator Fins broken',
        'Winglet Damage',
        'Winglet Missing',
        'Stall Strips damaged',
        'Stall Strips missing',
        'Gurney Flap. Damage',
        'Gurney Flap. Missing',
        'Dino Tails/ Shells. Damage',
        'Dino Tails/ Shells. Missing',
        'Lightning Damage',
        'Lightning Receptor Damage',
        'Blade Geometry Poor',
        'Laminate, Stress',
        'Erosion'
      ];
      this.extentOfDamagesInternal = [
        {
          display: 'NO DAMAGE',
          value: 'no damage'
        },
        {
          display: 'In outer laminate',
          value: 'in outer laminate'
        }, {
          display: 'In core material',
          value: 'in core material'
        }, {
          display: 'In inner laminate',
          value: 'in inner laminate'
        }, {
          display: 'In bond lines',
          value: 'in bond lines'
        }, {
          display: 'In spar cap',
          value: 'in spar cap'
        }, {
          display: 'Through Blade',
          value: 'through blade',
        }, {
          display: 'Internal Component (Internal inspection only)',
          value: 'internal component'
        },{
          display: 'VG damaged/missing',
          value: 'vg damaged/missing'
        },{
          display: 'LPS receptor missing',
          value: 'lps receptor missing'
        }
      ];
      this.extentOfDamagesExternal = [
        {
          display: 'NO DAMAGE',
          value: 'no damage'
        },
        {
          display: 'Item on blade surface (External inspection only)',
          value: 'item on blade surface'
        }, {
          display: 'In Coating (External inspection only)',
          value: 'in coating'
        }, {
          display: 'In outer laminate',
          value: 'in outer laminate'
        }, {
          display: 'In core material',
          value: 'in core material'
        }, {
          display: 'In inner laminate',
          value: 'in inner laminate'
        }, {
          display: 'In bond lines',
          value: 'in bond lines'
        }, {
          display: 'In Filler',
          value: 'in filler'
        }, {
          display: 'Through Blade',
          value: 'through blade',
        }, {
          display: 'VG damaged/missing',
          value: 'vg damaged/missing'
        }, {
          display: 'LPS receptor missing',
          value: 'lps receptor missing'
        }];
      this.bladeSides = [
        'pressure',
        'suction',
        'Leading Edge',
        'TE Main Shear Web',
        'Pressure Side',
        'Suction Side',
        'LE Main Shear Web',
        'TE Shear Web if exists',
        'Trailing Edge'
      ];
      this.externalBladeSides = [
        'Pressure Side',
        'Suction Side',
        'Leading Edge',
        'Trailing Edge',
      ];
      this.internalBladeSides = [
        'Pressure Side',
        'Suction Side',
        'Leading Edge',
        'LE Main Shear Web',
        'TE Main Shear Web',
        'TE Shear Web',
        'Trailing Edge'
      ];
      this.inspectionTypes = [
        'EXTERNAL',
        'INTERNAL',
        'LIGHTNING'
      ];
      this.chordDistances = [
        '0%',
        '0 - 25%',
        '25 - 50%',
        '50 - 75%',
        '75 - 100%',
        '100%'
      ];

      if (this.options.finding && this.options.finding._id) {
        this.finding = this.options.finding;
        let extentOfDamage = this.options.finding.extentOfDamage.toLowerCase();
        if(extentOfDamage == 'no damage') {
          this.options.finding.defectDepthThickness = 0;
          this.options.finding.defectLengthSpan = 0;
          this.options.finding.defectWidthChord = 0;
        }
        this.finding = {
          _id: this.options.finding._id,
          date: new Date(this.options.finding.date),
          blade: this.options.finding.blade._id,
          turbine: this.options.finding.turbine._id,
          type: this.options.finding.type,
          inspectionType: this.options.finding.inspectionType.toUpperCase(),
          bladeSide: this.options.finding.bladeSide,
          bladePosition: this.options.finding.bladePosition,
          cavityInspectionType: this.options.finding.cavityInspectionType,
          chordDistance: this.options.finding.chordDistance,
          defectDepthThickness: this.options.finding.defectDepthThickness,
          defectLengthSpan: this.options.finding.defectLengthSpan,
          defectWidthChord: this.options.finding.defectWidthChord,
          distanceFromRoot: this.options.finding.distanceFromRoot,
          numberOfDefects: this.options.finding.numberOfDefects,
          extentOfDamage: this.options.finding.extentOfDamage.toLowerCase(),
          uniqueReferenceNumber: this.options.finding.uniqueReferenceNumber,
          findingType: this.options.finding.findingType,
          numberOfDefects: this.options.finding.numberOfDefects,
          comments: this.options.finding.comments,
          woId: this.options.finding.woId,
          images: this.options.finding.images
        };
      } else {
        this.finding = {
          blade: this.options.blade._id,
          turbine: this.options.blade.turbine._id,
          bladePosition: this.options.blade.position,
          type: 'INSPECTION',
          date: new Date(),
          inspectionType: 'EXTERNAL',
          bladeSide: null,
          cavityInspectionType: null,
          chordDistance: null,
          defectDepthThickness: null,
          defectLengthSpan: null,
          defectWidthChord: null,
          distanceFromRoot: null,
          extentOfDamage: null,
          findingType: null,
          comments: null,
          woId: null,
          images: []
        };
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    bladeSideChanged() {
      // console.log('blade side changed: ', this.finding.bladeSide);
      if(this.finding.bladeSide) {
        if(this.finding.bladeSide.indexOf('Leading') > -1) {
          this.finding.chordDistance = '0%';
        } else if( this.finding.bladeSide.indexOf('Trailing') > -1) {
          this.finding.chordDistance = '100%';
        }
      }
    }

    saveFinding(form) {
      this.submitted = true;
      this.serverError = null;
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        if (this.options.finding && this.options.finding._id) {
          if (this.options.blade) {
            this.finding.bladePosition = this.options.blade.position;
          }
          if (this.options.blade && this.options.blade.turbine) {
            this.finding.turbineSerial = this.options.blade.turbine.serial;
          }
          this.Finding.update(this.finding).$promise
            .then(finding => {
              this.stopSpin('spinner-1');
              console.log(finding);

              this.bladeStr = '';
              if(this.options.finding.blade.make) {
                this.bladeStr+=this.options.finding.blade.make+' ';
              }
              if(this.options.finding.blade.model) {
                this.bladeStr+=this.options.finding.blade.model;
              }
              if(this.options.finding.blade.name) {
                this.bladeStr+=this.options.finding.blade.name;
              }

              this.turbineStr = '';
              if(this.options.finding.turbine.make) {
                this.turbineStr+=this.options.finding.turbine.make;
              }
              if(this.options.finding.turbine.model) {
                this.turbineStr+=this.options.finding.turbine.model;
              }
              if(this.options.finding.turbine.name) {
                this.turbineStr+=this.options.finding.turbine.name;
              }
              
              const activityLogData = {
                site: this.options.finding.turbine.site._id,
                turbine: this.options.finding.turbine._id,
                blade: this.options.finding.blade._id,
                finding: finding.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'edit',
                type: 'finding',
                sourceScreen: 'finding',
                eventString: `Finding No <a href="/blade/${this.options.finding.blade._id}/finding/${finding.data._id}">${finding.data.uniqueReferenceNumber}</a> was edited in wind farm <a href="/site/${this.options.finding.turbine.site._id}">${this.options.finding.turbine.site.name}</a>, turbine <a href="/turbine/${this.options.finding.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.options.finding.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
              }
        
              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.$uibModalInstance.close(finding);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          this.finding.bladePosition = this.options.blade.position;
          this.finding.turbineSerial = this.options.blade.turbine.serial;
          this.Finding.save(this.finding).$promise
            .then(finding => {
              this.stopSpin('spinner-1');
              console.log(finding);
              this.bladeStr = '';
              if(this.options.blade.make) {
                this.bladeStr+=this.options.blade.make+' ';
              }
              if(this.options.blade.model) {
                this.bladeStr+=this.options.blade.model;
              }
              if(this.options.blade.name) {
                this.bladeStr+=this.options.blade.name;
              }

              this.turbineStr = '';
              if(this.options.blade.turbine.make) {
                this.turbineStr+=this.options.blade.turbine.make;
              }
              if(this.options.blade.turbine.model) {
                this.turbineStr+=this.options.blade.turbine.model;
              }
              if(this.options.blade.turbine.name) {
                this.turbineStr+=this.options.blade.turbine.name;
              }
              const activityLogData = {
                site: this.options.blade.turbine.site._id,
                turbine: this.options.blade.turbine._id,
                blade: this.options.blade._id,
                finding: finding.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'create',
                type: 'finding',
                sourceScreen: 'blade',
                eventString: `Finding No <a href="/blade/${this.options.blade._id}/finding/${finding.data._id}">${finding.data.uniqueReferenceNumber}</a> was created in wind farm <a href="/site/${this.options.blade.turbine.site._id}">${this.options.blade.turbine.site.name}</a>, turbine <a href="/turbine/${this.options.blade.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.options.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
              }
        
              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.$uibModalInstance.close(finding);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log(err);
      }
    }

    openFindingDate() {
      this.findingDatePopup.opened = true;
    }

    removeImage(index) {
      this.finding.images.splice(index, 1);
    }

    uploadImage(file) {
      if (!file || !file.name) {
        return;
      }
      // only for add finding mode
      if(!this.finding._id) {
        this._findExif(file);
      }
      //console.log('image file:  ', file);
      var filename = file.name;
      var type = file.type;
      var query = {
        filename: filename,
        type: type
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise
        .then(result => {
          this.Upload.upload({
            url: result.url, //s3Url
            transformRequest: function (data, headersGetter) {
              var headers = headersGetter();
              delete headers.Authorization;
              return data;
            },
            fields: result.fields, //credentials
            method: 'POST',
            file: file
          }).progress((evt) => {
            this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
          }).success((data, status, headers, config) => {
            this.isUploading = false;
            // file is uploaded successfully
            // console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
            this.finding.images.push(result.url + '/' + result.fields.key);
          }).error((err) => {
            this.isUploading = false;
            console.log('err', err);
            this.toastr.success('Image was not uploaded to cloud. please try again later or contact administrator');
          });
        });
    }

    _findExif(file) {
      var reader = new FileReader();
      reader.onload = event => {
        try {
          var tags = ExifReader.load(event.target.result);
          // The MakerNote tag can be really large. Remove it to lower
          // memory usage if you're parsing a lot of files and saving the
          // tags.
          delete tags['MakerNote'];
          console.log('raw data: ', JSON.stringify(tags));
          if (tags['undefined-37510']) {
            var val = tags['undefined-37510'].value;
            var findingData = JSON.parse(val);
            if (findingData.data) {
              this.$timeout(() => {
                this.finding = findingData.data.finding;
                // console.log('findingData.data.finding.extentOfDamage : ', findingData.data.finding.extentOfDamage);
                this.finding.inspectionType = findingData.data.finding.type.toLowerCase();
                this.finding.extentOfDamage = findingData.data.finding.extentOfDamage.toLowerCase();
                if(!this.finding.images) {
                  this.finding.images = [];
                }
                // console.log('new finding: ', this.finding);
              }, 100, true);
            }
            // console.log('data: ', findingData);
          }
        } catch (error) {
          console.log('read exif failed: ', error);
        }
      };
      // We only need the start of the file for the Exif info.
      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
      //--reader.readAsDataURL(file);
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    onExtentOfDamageChange() {
      console.log(this.finding.extentOfDamage);
      if(this.finding.extentOfDamage == 'no damage') {
        this.finding.defectDepthThickness = 0;
        this.finding.defectLengthSpan = 0;
        this.finding.defectWidthChord = 0;
      }
      //  else {
      //   this.finding.defectDepthThickness = null;
      //   this.finding.defectLengthSpan = null;
      //   this.finding.defectWidthChord = null;
      // }
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditFindingModalCtrl', AddEditFindingModalCtrl);
})();
